<template>
  <v-container class="pb-12 pa-3 py-0">
    <v-row justify="center">

      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" :timeout="2000"  > 
        <strong class="subtitle-1 font-weight-black"> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>

      <v-col cols="12" class="py-0 pt-10">
        <!-- Titulo -->
        <div class="d-flex align-center">
          <v-avatar
            size="44"
            rounded
            class="elevation-0 transparent"
          >
            <v-img src="@/assets/iconos/estrellas.png" width="42" height="42"/>
          </v-avatar>
          <div class="ms-3 mt-4">
            <p class="text-xs mb-0">
              <h2 class="font-weight-bold">Tendencias</h2>
            </p>
          </div>
        </div>
      </v-col>

      <card_articulo :listado_productos="listado_productos"/>

      <v-btn fab fixed bottom left  color="primary" onClick="history.go(-1);"  > <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>
   
    </v-row>
  </v-container>
</template>

<script>
  import $ from 'jquery'
  import {mapGetters, mapActions} from 'vuex'
	import  metodos from '@/mixins/metodos.js';

  import card_articulo from '@/components/card_articulo.vue'


  export default {
		mixins:[metodos],

    components: {
      card_articulo,
    },

    data(){
      return{
        model: null,
        listado_productos:[],
        alerta: { activo: false, texto:'', color:'error' },
        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png'
      }
    },
   
    computed:{
      ...mapGetters('login',['getusuarioFetish']),
    },

    created(){
      this.init();
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),
      init(){
        $('body, html').animate({ scrollTop: '0px' }, 300);
        this.$http.get('obtener.novedades').then( response =>{
          this.listado_productos = response.body
        })
      },
      agregar_a_carrito(item){
        const payload = new Object({
          id        : item.id,
          codigo    : item.codigo,
          nomart    : item.nomart, 
          precio1   : item.precio1,
          precio2   : item.precio2,
          pjedesc   : item.pjedesc,
          descrip   : item.descrip,
          descripLarga : item.descripLarg,
          largo     : item.largo,
          ancho     : item.ancho,
          alto      : item.alto,
          peso      : item.peso,
          foto      : item.foto,
          envoltorio: item.envoltorio,
          cantidad  : 1
        })

        this.carritoadd(payload).then( response =>{
          // this.alerta = { activo: true, texto:`El producto se agrego al carrito`, color:'blue' }
        })
      },
      añadir_lista_deseos(item){
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,

        })
        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },
      ver (item){
        this.$router.push({ name: 'detalle.del.producto',params:{id:item.id}})
      }
    } 
    
  }
</script>

<style scoped>
 
</style>