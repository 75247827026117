<template>
	<v-container class="mb-12">
    <v-row>
      <v-carousel cycle height="auto" hide-delimiter-background hide-delimiters show-arrows-on-hover  >
        <template v-slot:prev="{ on, attrs }">
          <v-btn icon large color="white" v-bind="attrs" v-on="on" >  <v-icon> mdi-chevron-left </v-icon> </v-btn>  
          </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn icon large color="white" v-bind="attrs" v-on="on"> <v-icon> mdi-chevron-right </v-icon> </v-btn>
        </template>
        <v-carousel-item  
          v-for="(item2, j) in anuncios" :key="j" 
          :src="item2.foto" 
          contain
          >
            <v-row class="fill-height" align="end" justify="center">
              <v-col cols="12" class="text-center"> 
                <v-btn 
                  color="secondary" 
                  large
                  rounded elevation="12" @click="irA(item2.path)"> 
                <!-- Más Información {{ carruselActivo }} -->
                </v-btn> 
                
              </v-col>
            </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-container >
</template>

<script>
  export default {
    components: {
    },
    data () {
      return {
        anuncios:
          [{
            id     : 1, 
            foto   : require('@/assets/anuncios/Tendencias.webp'),
            path   : 'tendencias'
          },
          {
            id     : 2, 
            foto   : require('@/assets/anuncios/Regalos.webp'),
            path   : 'el.regalo.perfecto'
          },
          {
            id     : 3, 
            foto   : require('@/assets/anuncios/Juguetes.webp'),
            path   : 'mi.primer.juguete'
          },
        ],

       
      }
    },

    created(){
    },


    methods:{
      irA(path){
        this.$router.push({ name: path }); 
      }
    }
  }
</script>



