<template>
  <v-container  class="pa-4 py-0">
    <v-row justify="center">

      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
        <strong> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      
      <v-btn fab fixed bottom left  color="info" onClick="history.go(-1);"  > 
        <v-icon >mdi-arrow-left-thick</v-icon> 
      </v-btn>

      <v-col cols="12" sm="5" md="4" lg="3" offset-sm="7" offset-md="8" offset-lg="9" >
        <v-card-actions>
          <v-text-field
            v-model="codigo_promocion"  
            label ="Ingresa tu cupon" 
            outlined dense hide-details 
            append-icon="mdi-sticker-emoji"
          />
          <v-btn 
            small class="mx-2" 
            :disabled="codigo_promocion? false:true"
            @click="anadir_cupon_promocional()"  ><v-icon > mdi-magnify </v-icon> </v-btn>
        </v-card-actions>
      </v-col>
     
      <!-- RESUMEN DE PEDIDO -->
      <v-col cols="12" class="py-0" >
        <v-card class="shadowCard">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="getCarrito"
              :search="search"
              height="auto"
              fixed-header
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              :loading ="Loading"
            >

            	<template v-slot:item.foto="{ item }" > 
                <v-avatar tile v-if="item.foto"> <v-img :src="url+item.foto"></v-img> </v-avatar>
                <v-avatar tile v-else> <v-img :src="url+photo"></v-img> </v-avatar>

  			    	</template>
               <template v-slot:item.precio1 ="{ item }" > 
                <span> {{ item.precio1 | currency(2) }}</span>
                <!-- {{accounting.formatNumber(item.precio1)}} -->
  			    	</template>

              <template v-slot:item.pjedesc="{ item }" > 
                <span> {{ item.pjedesc }} %</span>
                <!-- {{accounting.formatNumber(item.pjedesc)}} -->
  			    	</template>

              <template v-slot:item.total="{ item }" > 
                <span> {{  (item.precio1 * item.cantidad) - (item.pjedesc / 100) * (item.precio1 * item.cantidad ) | currency(2) }} </span>
                <!-- {{accounting.formatNumber(item.pjedesc)}} -->
  			    	</template>

              <template v-slot:item.cantidad="{ item }" > 
                <v-card-actions>
                <v-btn x-small text dark color="secondary " @click="remove(item.id)"><v-icon >remove</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-chip  fab  outlined>{{ item.cantidad }}</v-chip>
                <v-spacer></v-spacer>
                <v-btn x-small text  color="success" @click="add(item)"><v-icon >add</v-icon></v-btn>
              </v-card-actions>
  			    	</template>

            	<template v-slot:item.actions="{ item }" > 
  			    		<v-btn fab icon color="error" class="ma-1"  small dark  @click="eliminar(item)">
                 <v-icon  > mdi-delete </v-icon>
  							</v-btn> 
  			    	</template>
            </v-data-table>
            <!-- PAGINACION -->
            <div class="text-center">
              <v-pagination  v-model="page" :length="pageCount" color="primary"  ></v-pagination>
            </div> 
          </v-card-text>
        </v-card>
      </v-col>

      <!-- DIRECCIONES -->
      <v-col col="12" sm="8" v-if="getusuarioFetish">
        <!-- DIRECCION DE ENVIO -->
        <v-card class="pa-2 shadowCard"  >
          <v-card-text class="font-weight-black pa-2" >Direecion de envío  </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="font-weight-medium">
              <span v-if="getusuarioFetish && direccionActiva"> {{ direccionActiva.colonia }}, {{ direccionActiva.calle }} {{ direccionActiva.numero }} </span>
              <span v-if="getusuarioFetish && !direccionActiva"> Agregue la dirección al que se enviará el producto  </span>

              <span v-if="!getusuarioFetish && envioLocal" > {{ envioLocal.data.colonia }}, {{ envioLocal.data.calle }} {{ envioLocal.data.numero }} </span>
              <span v-if="!getusuarioFetish && !envioLocal"> Agregue la dirección al que se enviará el producto  </span>

              <v-spacer></v-spacer>
              <v-btn tile color="primary" @click="consulta_direcciones()" v-if="direccionActiva"> <v-icon > mdi-magnify </v-icon> </v-btn>
              <v-btn tile color="info" @click="modalDireccion = true"> <v-icon > mdi-map-marker-plus </v-icon> </v-btn> 
            </v-card-actions>
            <v-expand-transition>
              <div v-show="verEnvio">
                <v-card flat class="tarjetas-pago" v-for="(item, i) in direccionesEnvio" :key="i">
                  <v-list-item >
                    <v-list-item-content >
                      <v-list-item-subtitle >
                        <span class="font-weight-black caption">  Nombre: </span> {{ item.nombre}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <span class="font-weight-black caption">  Direccion : </span> 
                          {{ item.colonia }}, {{ item.calle }} {{ item.numero }} 
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-btn icon color="green" dark v-if="item.activo"> 
                        <v-icon > mdi-checkbox-blank-circle </v-icon> 
                      </v-btn> 
                      <v-btn  icon color="error" @click="cambiaActivoEnvio(item.activo, item)"  v-else> 
                        <v-icon> mdi-checkbox-blank-circle-outline </v-icon> 
                      </v-btn>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card> 
              </div>
            </v-expand-transition>
        </v-card>
        <!-- DIRECCION DE FACTRACION 
        <v-card outlined class=" mt-1">
          <v-card v-ripple dark class="pa-2" >
            <v-card-text class="pa-0" >Direecion de Facturación</v-card-text>
            <v-divider class="darked"></v-divider>

            <v-card-actions>
              <span v-if="getusuarioFetish && facturacionActiva "> {{ facturacionActiva.colonia }}, {{ facturacionActiva.calle }} {{ facturacionActiva.numero }} </span>
              <span v-if="getusuarioFetish && !facturacionActiva"> Agregue la dirección de facturación </span>

              <span v-if="!getusuarioFetish && facturacionLocal"> {{ facturacionLocal.data.colonia }}, {{ facturacionLocal.data.calle }} {{ facturacionLocal.data.numero }} </span>
              <span v-if="!getusuarioFetish && !facturacionLocal">Agregue la dirección de facturación </span>

              <v-spacer></v-spacer>
              <v-btn fab small color="blue" @click="consulta_facturaciones()" v-if="facturacionActiva"> <v-icon> mdi-magnify </v-icon> </v-btn>
              <v-btn fab small color="secondary" @click="modalFacturacion = true"> <v-icon> mdi-map-marker-plus </v-icon> </v-btn> 
            </v-card-actions>

            <v-expand-transition>
              <div v-show="verFactura">
                <v-divider></v-divider>
                <v-card dark v-for="(item, i) in direccionesFacturacion" :key="i">
                  <v-list-item link>
                    <v-list-item-content >
                      <v-list-item-subtitle >
                      <span class="font-weight-black caption">  Nombre: </span> {{ item.nombre}} 
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                      <span class="font-weight-black caption">  Direccion : </span>
                        {{ item.colonia }}, {{ item.calle }} {{ item.numero }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar>
                      <v-btn fab x-small color="green" v-if="item.activo"> 
                        <v-icon> mdi-check </v-icon> 
                      </v-btn> 
                      <v-btn fab x-small outlined color="error" @click="cambiaActivoFactura(item.activo, item)"  v-else> 
                        <v-icon> mdi-close </v-icon> 
                      </v-btn>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card> 
              </div>
            </v-expand-transition>
          </v-card>
        </v-card> -->
      </v-col> 

      <!-- ALERTA DE SESION -->
      <v-col col="12" sm="8" v-if="!getusuarioFetish">
        <v-alert outlined type="info" text>
          <span> Para realizar una compra deberas iniciar sesión con tu cuenta. </span> <br>
          <span>  Si aun no te encuentras registrado(a), </span> <strong> ¡Que estas esperando! </strong> 
          <br><br>
           <span>  Da click en el icono 
            <v-icon color="primary">mdi-account</v-icon> 
            que se encuentra en la parte superior de la pantalla.
            </span> 
        </v-alert>
      </v-col> 

      <!-- TOTAL DEL CARRITO -->
      <v-col cols="12" sm="4">
        <v-card class="shadowCard">
          <v-card-actions class="pa-0">
            <v-card-text class="font-weight-black "> TOTAL DEL CARRITO </v-card-text>
          </v-card-actions>
            <v-divider class="pa-0"></v-divider>
          <v-list dense >
            <v-list-item  class="py-0">
              <v-list-item-content>
                <v-list-item-title > Subtotal</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title > {{ totales.subtotal | currency(2) }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item  class="py-0">
              <v-list-item-content>
                <v-list-item-title > Envío </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right" >
                <v-list-item-title v-if="totales.envio_gratis"> 
                  <v-btn text small color="success" class="font-weight-black"> Envío Gratis </v-btn>
                </v-list-item-title>
                <v-list-item-title v-else> 
                  {{ totales.envio | currency(2) }} 
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item  class="py-0" v-if="totales.descuento_cupon != 0">
              <v-list-item-content>
                <v-list-item-title > Cupon promocional </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title > {{ totales.descuento_cupon | currency(2) }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

              <v-list-item  class="py-0">
              <v-list-item-content>
                <v-list-item-title > Total </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="text-right">
                <v-list-item-title > {{ totales.total | currency(2) }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- <v-btn block color="secondary" @click="peticionPago()"> Proceder al pago</v-btn> -->
          <div class="cho-container" hidden></div>
        </v-card>

          <v-btn 
            elevation="5" 
            class="mt-1" tile block 
            color="primary" 
            @click="verificar_inventario()" 
            :disabled="PROCESAR_PAGO"
          > Proceder al pago
          </v-btn>

      </v-col>

      <!--
      <span class="white--text"> {{ totales }} </span>
      -->
      <v-dialog v-model="modalDireccion" width="800">
        <v-card class="pa-4">
          <controlDireccionEnvio 
            :modo="modo"
            @modal="modalDireccion = $event"
            @direccion="calculaEnvios = $event" 
          />
        </v-card>
      </v-dialog>

      <!-- MODAL DIRECCION DE FACTURACION 
      <v-dialog v-model="modalFacturacion" width="800">
        <v-card class="pa-4" dark>
          <controlDireccionFacturacion 
            :modo="modo"
            @modal="modalFacturacion = $event" 
          />
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalExistencia" width="800" persistent>
        <v-card class="alerta" color="error">
          <v-card class="" outlined dark >
          <v-card-actions>
             <v-card-text class="text-center font-weight-black subtitle-2 text-sm-h5 error--text">
              ¡Lo sentimos!. No tenemos en existencia  {{ existencias.length > 1 ? 'estos productos.':' este producto.' }} 
            </v-card-text>
            <v-btn text dark  @click="modalExistencia = false"> <v-icon large>clear</v-icon></v-btn>
          </v-card-actions>
           
            <v-list-item link v-for="(item, i) in existencias" :key="i" >
              <v-list-item-avatar :size="$vuetify.breakpoint.name === 'xs'? 60:100  " tile>
                <v-img :src="url + item.foto" v-if="item.foto"></v-img>
                <v-img :src="`${url + photo}`"  v-else/>
              </v-list-item-avatar>
              <v-list-item-content >
                <v-list-item-subtitle class="pb-0 text-xs-caption text-sm-subtitle-1">
                <span class="font-weight-black" v-if="$vuetify.breakpoint.name != 'xs'">  Código: </span> {{ item.codigo }}  
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-xs-caption text-sm-subtitle-1">
                <span class="font-weight-black " v-if="$vuetify.breakpoint.name != 'xs'">  Producto : </span>  {{ item.nomart}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="mt-2"/>
          </v-card>
        </v-card>
      </v-dialog>
      -->

		  <overlay v-if="overlay"/>

    </v-row>
  </v-container>
</template>

<script>
  import  metodos from '@/mixins/metodos.js';
	import {mapGetters, mapActions}    from 'vuex'
  import overlay                     from '@/components/overlay.vue'
  import controlDireccionEnvio       from '@/views/Cliente/control_direccion_envio.vue'
  import controlDireccionFacturacion from '@/views/Cliente/control_direccion_facturacion.vue'
	var accounting = require("accounting");
  const mp = new MercadoPago('TEST-5a054ef7-cd86-4517-9841-5ede8fb6705e', {
    // locale: 'es-AR'
  });

  
  export default {
    mixins:[metodos],
    components:{
      // loading,
      overlay,
      controlDireccionEnvio,
      controlDireccionFacturacion
    },
    data(){
      return{
        checkout: null,
        codigo_promocion: '',
        datos_cupon     : {},
        descuento_cupon: 0,
        page: 0,
        pageCount: 0,
        itemsPerPage: 100,
        search:'',
        pedidos:[],
        headers: [
          // { text: '#'         , align: 'start'  , value: 'id'      ,sortable: false, },
          { text: 'Foto'      , align: 'left'    , value: 'foto'    ,sortable: false, },
          { text: 'Producto'  , align: 'left'    , value: 'nomart'                    },
          { text: 'Precio'    , align: 'center'  , value: 'precio1'                   },
          { text: 'Descuento' , align: 'center'  , value: 'pjedesc'                   },
          { text: 'Cantidad'  , align: 'center'  , value: 'cantidad' ,sortable: false },
          { text: 'Subtotal'  , align: 'right'   , value: 'total'    ,sortable: false },
          { text: 'Eliminar'  , align: 'right'   , value: 'actions'  ,sortable: false },
        ],

        alerta: { activo: false, texto:'', color:'error' },
        Loading: false,
        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png',
        
        modo: 3, 
        modalDireccion: false,
        modalFacturacion: false,
        verEnvio: false,
        verFactura: false,
        overlay: false,

        envio: null,
        calculaEnvios: false,
        idPago:'',

        modalExistencia: false,
        existencias    :[],
        datosPedido: null,
        banderaPagoExiste:'',

      }
    },

    filters:{
      currency(val, dec){
        return accounting.formatMoney(val, '$', dec) 
      }
    },
  
    computed: {
      ...mapGetters('login'  ,['getusuarioFetish','CIAWS']),
      ...mapGetters('Carrito',['getCarrito']),
      ...mapGetters('Direcciones',['direccionActiva',
                                   'factura cionLocal',
                                   'envioLocal',
                                   'direccionesEnvio',
                                   //'facturacionActiva',
                                   //'direccionesFacturacion'
                                  ]),
      
      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -290;
      },

      totales(){
        let subtotal = 0,envios = 0, total = 0, envio_gratis = false; // SE DECLARAN TODAS LAS VARIABLES A UTILIZAR
        for(const i in this.getCarrito){ // SE GENERA UN CICLO PARA OBTENER TODOS LOS PRECIOS DE LOS PRODUCTOS DENTRO DEL CARRITO
          subtotal = subtotal + ( (this.getCarrito[i].precio1 - (this.getCarrito[i].pjedesc / 100) * (this.getCarrito[i].precio1) ) * this.getCarrito[i].cantidad );
        }
        this.envio != null ? envios = this.envio.totalPrice : envios; // RECOLECTO EL PRECIO DEL ENVIO PARA PODER SUMARLO AL SUBTOTAL
        // total = (subtotal - this.descuento_cupon) + envios;   // ASIGNO EL TOTAL SUMANDO EL SUBTOTAL MENOS EL DESCUENTO DE CUPON MAS EL COSTO DE ENVIO
        total = (subtotal - this.descuento_cupon);   // ASIGNO EL TOTAL SUMANDO EL SUBTOTAL MENOS EL DESCUENTO DE CUPON MAS EL COSTO DE ENVIO
        
          // console.log('envio ', envios);
        if(this.CIAWS.enviogratis === 1){
          
          if(total >= this.CIAWS.costo){
            total = total;
            envio_gratis = true;
            envios       = 0;
          }else{
            total = total + envios
            envio_gratis = false;
          }
        }else{
          total = total + envios;
        }
        return { 
                  subtotal       : subtotal,
                  envio          : envios,
                  total          : total,
                  descuento_cupon: this.descuento_cupon, 
                  envio_gratis   : envio_gratis 
              } // RETORNO EL OBJ CON TODOS LOS VALORES ASIGNADOS.
        
      },

      PROCESAR_PAGO(){
        if(this.getusuarioFetish){
          if(this.direccionActiva){ 
            return false
          }else{
            return true
          }
        }else{
          if(this.envioLocal){
            return false
          }else{
            return true
          }
        }
      }
    },

    watch: {
      getusuarioFetish(){
        this.initialize();
      },

      calculaEnvios(){
        if(this.calculaEnvios){
          this.calcular_precio_envio();
        }
        let that = this; setTimeout(()=> {that.calculaEnvios = false }, 500); 
      }

    },

    async created () {
      var response = window.location;
      //Convertimos en array las variables en el GET
      var pago = {};
      var arreglos = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        pago[key] = value;
      });

      if(pago.status == 'approved'){
        // Pago aprovado
        // Paso #1, validar si ya existe ese pago registrado
        let pagoExiste = await this.validarExistePago(pago.collection_id)
        if(this.banderaPagoExiste.length == 0){
          // El pedido no existe, hay que agregarlo, agregar el pago de mercado pago y actualizar el estatus del movim
          // En este punto el pago ya se acepto ya hay que descontar del almácen los artículos
          await this.addPagoMercadopago(pago)
          await this.generarEnvio()
          await this.updateEstatusPedido(pago) // Actualizar el movim
          // y ahora siiii eliminar lo del carrito
          // y mostrar alerta de pedido realizado correctamente
        }else{
          // Agregar alerta de que ya se realizo el pago
          this.alerta_de_proceso_pedido()
        }
      }else{
        // Pago no aprobado, favor de generar un dialogo y eliminar datos registrados
        await this.eliminarTodo()
      }

      this.initialize();
    },

    methods: {
      ...mapActions('Direcciones',['obtener_direcciones_envio',
                                   'obtener_direccion_envio_activa',
                                   //'obtener_direccion_facturacion_activa',
                                   //'obtener_direcciones_facturacion'
                                  ]),

      initialize () {
        if(this.getusuarioFetish){
          console.log('Si hay sesion activa', this.getusuarioFetish)
          this.obtener_direccion_envio_activa(this.getusuarioFetish.idcliente).then( ()=>{ this.calcular_precio_envio();  });
          this.$store.state.Direcciones.envio_local = null;
          //this.obtener_direccion_facturacion_activa(this.getusuarioFetish.idcliente);
          //this.$store.state.Direcciones.facturacion_local = null;
        }

        if(!this.getusuarioFetish){
          
          console.log('No hay sesion activa', this.getusuarioFetish)
          this.$store.state.Direcciones.direccion_activa = null;
          //this.$store.state.Direcciones.facturacion_activa = null;
          this.calcular_precio_envio(); 
        }

      },

      verificar_inventario(){
        // this.peticionPago();

        this.overlay = true; 
        this.$http.post('validar_inventario', this.getCarrito).then( response =>{
          if(response.body.length>0){
           this.existencias = response.body;
           this.modalExistencia = true; 
            // Alerta, los artículos mostrados en el response, no hay existencia
          }else{
            this.datosPedido = response.body
            this.peticionPago();
          }
        }).catch(error =>{ 
          console.log('error', error.body)
        }).finally( ()=>{ this.overlay = false})
      },

      peticionPago () {
        // DEFINIR OBJETO QUE MANDARE
        let mercadopago = new Object( { productos: [], envio: this.totales.envio } )
        
        // if(this.descuento_cupon)

        // FORMAR OBJETO ESPECIFICO PARA MERCADO PAGO 
        // for(let i=0; i< this.getCarrito.length; i++){
        //   mercadopago.productos.push({
        //     title: this.getCarrito[i].nomart,
        //     quantity: this.getCarrito[i].cantidad,
        //     currency_id: 'MXN',
        //     unit_price: this.getCarrito[i].precio1 - (this.getCarrito[i].pjedesc / 100) * (this.getCarrito[i].precio1),
        //   });
        // }

         mercadopago.productos.push({
            title: 'Ky-Fetish Productos',
            quantity: this.getCarrito.length,
            currency_id: 'MXN',
            unit_price: this.totales.total,
          });

        // Generar los datos del pedido
        let direccion_envio = null
        //let direccion_factura = null

        if(this.getusuarioFetish){
          direccion_envio   = this.direccionActiva
          //direccion_factura = this.facturacionActiva
        }else{
          if(this.envioLocal){        direccion_envio     = this.envioLocal.data }
          //if(this.facturacionActiva){ direccion_factura   = this.facturacionLocal.data }
        }
        const payload = {
          idmercadopago    : this.idPago,
          id_cliente       : this.getusuarioFetish? this.getusuarioFetish.idcliente : 0,
          direccion_envio,
          //direccion_factura,
          productos        : this.getCarrito,
          totales          : this.totales,
          mercadopago      : mercadopago,
          idcupon          : this.datos_cupon
        }
     
        /*
          En la siguiente api, lo que se hará, será mandar los datos del pedido, para guardar tanto direcciones como los artículos
          el response de la api, traerá los id de las direcciones guardadas
        */
        this.$http.post('mercadopago.add', payload).then(response=>{
          this.idPago = response.body.idPago
          // Inicializa el checkout
          this.checkout =  mp.checkout({
            preference: { id: this.idPago }, 
            render: { 
              container: '.cho-container',
              label: 'Pagar',
            },
            autoOpen: true
          });
        }).catch(error=>{console.log(error)})
      },

      eliminar(position){
        for(let i =0; i < this.getCarrito.length; i++){
          if(this.getCarrito[i].id === position.id){
    	    	this.getCarrito.splice(i,1);
            this.calcular_precio_envio();
          }
        }
    	},

      consulta_direcciones(){
        if(!this.verEnvio){
          this.obtener_direcciones_envio(this.getusuarioFetish.idcliente);
        }
        this.verEnvio = !this.verEnvio;
      },

      cambiaActivoEnvio(activo = false, item = {}){
        this.overlay = true; 
        const payload = new Object({ 
          idcliente :this.getusuarioFetish.idcliente,
          data      :item,
        })
        this.$http.post('cambiar.direccion.envio.activa' , payload ).then( response =>{
         this.obtener_direcciones_envio(this.getusuarioFetish.idcliente).then( response =>{
           this.obtener_direccion_envio_activa(this.getusuarioFetish.idcliente).then( ()=>{ this.calcular_precio_envio();  });
         }).finally(()=>{
            this.overlay = false; this.verEnvio = !this.verEnvio; 
         })
        }).catch( error =>{
					this.alerta = { activo: true, texto: error.body.message , color:'error'};
        })
      },

      //consulta_facturaciones(){
      //  if(!this.verFactura){
      //    this.obtener_direcciones_facturacion(this.getusuarioFetish.idcliente);
      //  }
      //  this.verFactura = !this.verFactura;
      //},  

      //cambiaActivoFactura(activo = false, item = {}){
      //  this.overlay = true; 
      //  const payload = new Object({ 
      //    idcliente :this.getusuarioFetish.idcliente,
      //    data      :item,
      //  });
      //  this.$http.post('cambiar.direccion.facturacion.activa' , payload ).then( response =>{
      //   this.obtener_direcciones_facturacion(this.getusuarioFetish.idcliente).then( response =>{
      //     this.obtener_direccion_facturacion_activa(this.getusuarioFetish.idcliente);
      //   }).finally(()=>{
      //      this.overlay = false; this.verFactura = !this.verFactura; 
      //   })
      //  }).catch( error =>{
			//		this.alerta = { activo: true, texto: error.body.message , color:'error'};
      //  })
      //},

      add(item){
        for(let i =0; i < this.getCarrito.length; i++){
          if(this.getCarrito[i].id === item.id){
            this.getCarrito[i].cantidad += 1;
            this.calcular_precio_envio();
          }
        }
    	},

    	remove(id){
        for(let i =0; i < this.getCarrito.length; i++){
          if(this.getCarrito[i].id === id){
            if(this.getCarrito[i].cantidad == 1){
    		    	this.getCarrito.splice(i,1)
            }else{
              this.getCarrito[i].cantidad -= 1
            }
          }
        }
				this.calcular_precio_envio();

    		// if(this.getCarrito[position].cantidad == 1){
    		// 	this.getCarrito.splice(position,1)
    		// }else{
    		// 	this.getCarrito[position].cantidad -= 1
    		// }
    	},

      validarExistePago (collection_id) {
        return this.$http.get('mercadopago.validarpago/' + collection_id).then(response=>{
          this.banderaPagoExiste = response.body
        }).catch(error=>{
          console.log(error)
        })
      },

      addPagoMercadopago (pago) {
        return this.$http.post('mercadopago.add.pedido',pago).then(response=>{
          // console.log('se agrego el pago de mercadopago',response.body)
        }).catch(error=>{
          console.log(error)
        })
      },

      updateEstatusPedido (pago) {
        return this.$http.put('mercadopago.update/' + pago.preference_id).then(response=>{
          // console.log('se agrego el pago de mercadopago',response.body)
        }).catch(error=>{
          console.log(error)
        })
      },

      eliminarTodo () {
        // esta función no esta terminada no elimina nada, pero mañana lo hago 21/09/2021
        return this.$http.post('mercadopago.eliminar.pedido',this.datosPedido).then(response=>{
          // console.log('se agrego el pago de mercadopago',response.body)
        }).catch(error=>{
          console.log(error)
        })
      },

      alerta_de_proceso_pedido() {
        this.$swal({
          title: "¡Tu pedido se procesó correctamente!",
          width:"auto",
          icon: 'success',
          html:'Este es tu número de pedido: <br> <br>' +  `<b>${this.idPago} </b>` ,
        });
      },

      async anadir_cupon_promocional(){
        this.datos_cupon = await this.validar_codigo_cupon();  //* MANDO A VALIDAR EL CODIGO REGISTRADO
        let total = this.totales;
        // console.log('datos_cupon', this.datos_cupon)
        // EVALUAR EL TIPO DE DESCUENTO QUE SE TIENE QUE REALIZAR
        if(this.datos_cupon.tipo === 1){ // ES UN PRECIO FIJO
          this.descuento_cupon = this.datos_cupon.descuento;
        }else if(this.datos_cupon.tipo === 2){ // ES UN PORCENTAJE DE DESCUENTO
          this.descuento_cupon = ( this.datos_cupon.descuento / 100 ) * total.subtotal
        }

        this.alerta = { activo: true, texto: "Cupon promocional agregado correctamente." , color:'success'}

      },

      validar_codigo_cupon(){
        return new Promise( (resolve, reject) => {
          const payload = new Object({ cupon: this.codigo_promocion, fecha: this.traerFechaActual() })
          this.$http.post('validar.cupon.existente', payload).then( response =>{
            resolve(response.body[0])
          }).catch( error => {
            this.alerta = { activo: true, texto: error.body.message, color:'error'}
          })
        })

       
      }
    },
  }
</script>

<style scoped>
  .alerta{
    padding: 2px; 
  }

  .tarjetas-pago{
    background: rgba(96, 95, 165, 0.30);
    margin: 2px 2px 2px 2px;
  }
</style>
