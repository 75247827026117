var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"elevation-0 transparent",attrs:{"size":"44","rounded":""}},[_c('v-img',{attrs:{"src":require("@/assets/iconos/estrellas.png"),"width":"42","height":"42"}})],1),_c('div',{staticClass:"ms-3 mt-4"},[_c('p',{staticClass:"text-xs mb-0"}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Productos más vendidos")]),_c('p')])],1),_c('v-sheet',{staticClass:"mx-auto transparent",attrs:{"elevation":"0","color":"#f6f9fc"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"success","show-arrows":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.productos),function(item,i){return _c('v-slide-item',{key:i},[_c('v-card',{staticClass:"shadowCard lateral ma-4",attrs:{"width":_vm.$vuetify.breakpoint.name != 'xs'? '300px': '200px'}},[(item.pjedesc > 0)?_c('v-btn',{staticClass:"v-btn--descuento text-caption",attrs:{"color":"primary","absolute":"","top":"","left":"","x-small":"","rounded":""}},[_vm._v(" "+_vm._s(item.pjedesc)+"% Descuento ")]):_vm._e(),_c('v-btn',{staticClass:"v-btn--descuento text-caption",attrs:{"icon":"","absolute":"","top":"","right":"","small":"","rounded":""},on:{"click":function($event){return _vm.ver(item)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-eye")])],1),(_vm.getusuarioFetish)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn--descuento text-caption mt-7",attrs:{"icon":"","absolute":"","top":"","right":"","small":"","rounded":""},on:{"click":function($event){return _vm.aniadir_lista_deseos(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-heart")])],1)]}}],null,true)},[_c('span',[_vm._v("Añadir a lista de deseos")])]):_vm._e(),(item.foto)?_c('v-img',{staticClass:"white",attrs:{"src":_vm.url + item.foto,"height":"250px","contain":""},on:{"click":function($event){return _vm.ver(item)}}}):_c('v-img',{attrs:{"src":("" + (_vm.url + _vm.photo)),"height":"250px","contain":"","color":"secondary"},on:{"click":function($event){return _vm.ver(item)}}}),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(_vm._s(item.nomart))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(item.pjedesc === 0)?_c('span',[_c('h4',{staticClass:"primary--text mr-2 mb-0"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.precio1,2))+" MX ")])]):_c('span',{staticClass:"primary--text mr-2 mb-0"},[_c('h4',{staticClass:"primary--text mr-2 mb-0"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.precio1) - (item.pjedesc / 100) * (item.precio1 ),2))+" MX "),(item.pjedesc > 0)?_c('span',{staticClass:"text-decoration-line-through grey--text"},[_vm._v(" $"+_vm._s(item.precio1)+" ")]):_vm._e()])])])],1),_c('v-btn',{staticClass:"v-btn--example",attrs:{"color":"primary","outlined":"","absolute":"","button":"","right":""},on:{"click":function($event){return _vm.agregar_a_carrito(item)}}},[_c('v-icon',[_vm._v("mdi-cart")])],1)],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }