import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'
const axios = require('axios');

export default {
	data () {
		return {
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': 'Bearer 8df7dc4cb48b5c19d9e0c7f6cf07abdfdd6d2e2d90bc34b2711a85174ff087de'
			},
      token:'8df7dc4cb48b5c19d9e0c7f6cf07abdfdd6d2e2d90bc34b2711a85174ff087de'
		}
	},
	computed: {

		...mapGetters('login'  ,['getusuarioFetish']),
		...mapGetters('Carrito',['getCarrito']),
		...mapGetters('Direcciones',['direccionActiva',
																 'facturacionActiva',
																 'facturacionLocal',
																 'envioLocal',
																]),
      ...mapGetters('Listado'  ,['historial_productos']),

	},

	methods: {
		...mapActions('Listado',['buscar_productos_categoria','buscar_productos_subcategorias']),

		traerFechaActual(){
			var f = new Date(); 
			return f.getFullYear() +'-'+ (f.getMonth() + 1 < 10? '0' + (f.getMonth() + 1): f.getMonth() + 1 ) +'-'+ (f.getDate()<10?'0'+f.getDate():f.getDate());
		},
		traerHoraActual(){
			var f = new Date(); 
			return (f.getHours()<10? '0'+f.getHours(): f.getHours()) + ':' + (f.getMinutes()<10? '0'+ f.getMinutes(): f.getMinutes())
		},
		consulta_ciudades(){
			this.$http.get('obtener.ciudades.activas').then( response =>{
				this.ciudades = response.body; 
			}).catch( error => {
				console.log('error ciudades', error)
			})
		},
		aniadir_producto_lista_deseos(payload){
			return new Promise( (resolve, reject) => {
				this.$http.post('anadir.lista.deseos', payload).then( response =>{
					resolve(response.body.message);
        }).catch( error =>{
					reject(error.body.message)
        })
			})
		},
		ver_carrito(){
			this.$store.state.Carrito.carritovisible =! this.$store.state.Carrito.carritovisible
		},
		ciudades_por_codigo_postal(cp) {
			// console.log('cp', cp)
			let config = {
				method: 'GET',
				url: 'https://geocodes.envia.com/zipcode/MX/' + cp,
				headers:{ 
					'Content-Type': 'application/json', 
					'Authorization': 'Bearer 8df7dc4cb48b5c19d9e0c7f6cf07abdfdd6d2e2d90bc34b2711a85174ff087de'
				},
			};

			axios(config).then((response) => {
				// console.log(JSON.stringify(response.data));
				this.colonias 		 = response.data[0].suburbs
				this.ObjectDefault.municipio = response.data[0].locality
				this.ObjectDefault.estado    = Object.values(response.data[0].state.code)[1]; 
				// this.respuesta = JSON.stringify(response.data)
			}).catch((error) => {
				console.log(error);
				// this.respuesta = error
			});
		},

		calcular_precio_envio(){
			var direccion = null;
			if(!this.getusuarioFetish){ 
				if(this.envioLocal == null){ 
					return
				}
			}

			// console.log('this.direccionActiva', this.direccionActiva)
			// console.log('this.envioLocal', this.envioLocal)
			this.getusuarioFetish ? direccion = this.direccionActiva : direccion = this.envioLocal.data;
			var contenido = '', peso = 0,  alto = null, ancho = null , largo = null;
			
			for(let i=0; i< this.getCarrito.length; i++){
				contenido = contenido + this.getCarrito[i].nomart	+ ', ';
				alto > this.getCarrito[i].alto ? alto = alto : alto = this.getCarrito[i].alto;
				
				if(this.getCarrito[i].cantidad > 1){
					peso  = peso  + (this.getCarrito[i].peso  * this.getCarrito[i].cantidad);
					ancho = ancho + (this.getCarrito[i].ancho * this.getCarrito[i].cantidad);	
				}else{ 
					peso      = peso + this.getCarrito[i].peso;
					ancho     = ancho + this.getCarrito[i].ancho;
				}
			}


			const body = {
				"origin": {
					"name": "GERARDO SANCHEZ VILLAREAL",
					"company": "GRUPO ACALG SA de CV",
					"email": "compras@acalg.com",
					"phone": "8125572046",
					"street": "Paseo de los estudiantes",
					"number": "2100",
					"district": "Cumbres 2do Sector",
					"city": "Monterrey",
					"state": "NL",
					"country": "MX",
					"postalCode": "64610",
					"reference": ""
				},
				
				"destination" : {
						"name"    : direccion.nombre + ' ' + direccion.apellido, // NOMBRE DEL USUARIO
						"company" : "",    // COMAPÑIA N/A
						"email"   : direccion.email,  // EMAIL DEL USUARIO
						"phone"   : direccion.telefono,         // TELEFONO DEL USUARIO
						"street"  : direccion.calle,                  
						"number"  : direccion.numero,
						"district": direccion.colonia,
						"city"    : direccion.municipio,
						"state"   : direccion.estado,
						"country" : "MX",                // SIEMPRE MEXICO
						"postalCode": direccion.cp,          // CP DE ENVIO
						"reference": ""
				},
				"packages": [
					{
							"content": contenido,  // CONCATENAR NOMBRES ARTICULOS
							"amount": 1,           // TOTAL DE TODOS LOS PRODUCTOS    
							"type": "box",
							"weight": peso,       // SUMA DE TODOS 
							"insurance": 0,
							"declaredValue": 0,
							"weightUnit": "KG",
							"lengthUnit": "CM",
							"dimensions": {
									"length": alto,      // ALTURA MAYOR 
									"width": ancho,       // SUMAS DE TODOS LOS ANCHOS
									"height": alto       // ALTURAS LA MAYOR
							}
					}
				],
				"shipment": {
					"carrier": "noventa9Minutos",
					"type": 1
				},
				"settings": {
						"currency": "MXN"
				}
			}
			// console.log('body 1', body);
			let config = {
				method: 'POST',
				// url: 'https://api-test.envia.com//ship/rate/', // PRUEBA  
				url: 'https://api.envia.com/ship/rate/',   // PRODUCCION
				// headers: {},
				headers: { 
					// 'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json', 
					// 'Authorization': 'Bearer 3567c08abd08671458788c70ce41c241c75963365de74ee44bd8f3def40c3ddc' // PRUEBA
					'Authorization': 'Bearer 8df7dc4cb48b5c19d9e0c7f6cf07abdfdd6d2e2d90bc34b2711a85174ff087de'    // PRODUCCION
				},
				data: body 
			};
			// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

			axios(config).then((response) => {
				this.envio = response.data.data[0];
			})
			.catch((error) => {
				console.log(error);
				this.respuesta = error
			});
		},

		generarEnvio(idpago){
			return { estatus: true, mensaje:'Pedido generado correctamente.'};
			// return new Promise( (resolve) => {
			// 	resolve({ estatus: true, mensaje:'Pedido generado correctamente.'});
			// });

			// var direccion = null;
			// if(!this.getusuarioFetish){ 
			// 	if(this.envioLocal == null){ 
			// 		return
			// 	}
			// }

			// this.getusuarioFetish ? direccion = this.direccionActiva : direccion = this.envioLocal.data;
			// var contenido = '', peso = 0,  alto = null, ancho = null , largo = null
			
			// for(let i=0; i< this.getCarrito.length; i++){
			// 	contenido = contenido + this.getCarrito[i].nomart	+ ', ';
			// 	alto > this.getCarrito[i].alto ? alto = alto : alto = this.getCarrito[i].alto;

			// 	if(this.getCarrito[i].cantidad > 1){
			// 		peso  = peso  + (this.getCarrito[i].peso  * this.getCarrito[i].cantidad);
			// 		ancho = ancho + (this.getCarrito[i].ancho * this.getCarrito[i].cantidad);	
			// 	}else{ 
			// 		peso      = peso + this.getCarrito[i].peso;
			// 		ancho     = ancho + this.getCarrito[i].ancho;
			// 	}
			// }

			// const body = {
			// 	"origin": {
			// 		"name": "GERARDO SANCHEZ VILLAREAL",
			// 		"company": "GRUPO ACALG SA de CV",
			// 		"email": "compras@acalg.com",
			// 		"phone": "8125572046",
			// 		"street": "Paseo de los estudiantes",
			// 		"number": "2100",
			// 		"district": "Cumbres 2do Sector",
			// 		"city": "Monterrey",
			// 		"state": "NL",
			// 		"country": "MX",
			// 		"postalCode": "64610",
			// 		"reference": ""
			// 	},
				
			// 	"destination" : {
			// 			"name"    : direccion.nombre + ' ' + direccion.apellido, // NOMBRE DEL USUARIO
			// 			"company" : "",    // COMAPÑIA N/A
			// 			"email"   : direccion.email,  // EMAIL DEL USUARIO
			// 			"phone"   : direccion.telefono,         // TELEFONO DEL USUARIO
			// 			"street"  : direccion.calle,                  
			// 			"number"  : direccion.numero,
			// 			"district": direccion.colonia,
			// 			"city"    : direccion.municipio,
			// 			"state"   : direccion.estado,
			// 			"country" : "MX",                // SIEMPRE MEXICO
			// 			"postalCode": direccion.cp,          // CP DE ENVIO
			// 			"reference": ""
			// 	},
			// 	"packages": [
			// 		{
			// 				"content": contenido,  // CONCATENAR NOMBRES ARTICULOS
			// 				"amount": 1,           // TOTAL DE TODOS LOS PRODUCTOS    
			// 				"type": "box",
			// 				"weight": peso,       // SUMA DE TODOS 
			// 				"insurance": 0,
			// 				"declaredValue": 0,
			// 				"weightUnit": "KG",
			// 				"lengthUnit": "CM",
			// 				"dimensions": {
			// 						"length": alto,      // ALTURA MAYOR 
			// 						"width": ancho,       // SUMAS DE TODOS LOS ANCHOS
			// 						"height": alto       // ALTURAS LA MAYOR
			// 				}
			// 		}
			// 	],
			// 	"shipment": {
			//     "carrier": "noventa9Minutos",
			//     "service": "local_next_day",
			//     "type": 1
			//   },
			// 	"settings": {
	    //     "currency": "MXN",
	    //     "printFormat" : "PDF",
	    //     "printSize":"PAPER_7X4.75",
	    //     "cashOnDelivery": "0",
	    //     "comments": ""
		  //   }
			// }

			// let config = {
			// 	method: 'POST',
			// 	url: 'https://api.envia.com/ship/generate/',
			// 	headers: { 
			// 		'Content-Type': 'application/json', 
			// 		'Authorization': 'Bearer 8df7dc4cb48b5c19d9e0c7f6cf07abdfdd6d2e2d90bc34b2711a85174ff087de'
			// 	},
			// 	data: body 
			// };
				
			/****************************************************************************************************************/
			// Aquí, antes que nada, vas a validar siiii en rastreos existe ya ese idpago, no vassss a generar el envíooooo
			/****************************************************************************************************************/
			// return new Promise( (resolve, reject) => {
			// 	this.$http.get('obtener.rastreo/' + idpago).then(res => { 
			// 		// Evaluo si la respuesta viene con al menos 1 elemento.
			// 		if(res.body.length){
			// 			resolve({ estatus: false, mensaje:'Este pedido ya se había generado anteriormente.'}) // Si la respuesta es true entonces retorno falso para lanzar alerta o retornar.
			// 		}else{ // Si la respuesta es false entonces paso a generar el envio.
			// 			console.log('AL ENTRAR AQUI SE GENERA EL ENVIO');
			// 			// return; 

			// 			// axios(config).then((res2) => {
			// 				const payload = new Object({
			// 						idpago: "681744925-593ce90d-7d01-49f0-be7f-7e24012ff72c",
			// 						carrier: "noventa9Minutos",
			// 						currency: "MXN",
			// 						currentBalance: "22.44",
			// 						label: "https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/noventa9Minutos/172487986883832616e56fcde205.pdf",
			// 						service: "local_next_day",
			// 						totalPrice: "75.4",
			// 						trackUrl: "https://envia.com/rastreo?label=1724879868&cntry_code=mx",
			// 						trackingNumber: "1724879868"
			// 				});
							
			// 				// if(res2.data.meta == 'generate'){
			// 					// let envio = { ...res2.data.data[0], idpago : idpago};
			// 					let envio = { ...payload, idpago : idpago};

			// 					// Insertart los datos de rastreo que serían los de this.envíoY como dato para relacionar todo, vas a guardar tambien el id idpago
			// 					this.$http.post('agregar.rastreo', envio).then(res3 => { 
			// 						console.log('res3', res3.body);
			// 						resolve({ estatus: true, mensaje:'Envio generado correctamente.', data: envio});

			// 					}).catch(err3 => {
			// 						resolve({ estatus: false, mensaje:'Tuvimos un problema al guardar la informacion del envio.'});
			// 						console.error('err3',err3);
			// 					});
								
			// 				// }else{
			// 				// 	console.error('Error al generar el envío, pero no te preocupes, solo llama al servicio para que hagan el envío');
			// 				// }
		
			// 			// }).catch((error) => {
			// 			// 	resolve({ estatus: false, mensaje:'Error al generar el envío, no te preocupes contactanos para hacer tu envío.'})
			// 			// 	console.log(error);
			// 			// });


			// 		}

			// 	}).catch( error => {
			// 		console.log('ERROR RASTREO', error);
			// 	});
			// })
		},


		consulta_posicion_historial(){
			if(this.historial_productos.length){
				if(this.historial_productos.length === 1){ 
					this.$store.state.Listado.historial.splice(0,1);
					history.back(); 
				}
				let posicion = this.historial_productos.length -2
				let ruta     = this.historial_productos[posicion];

				if(ruta.modo === 1){
					this.buscar_productos_categoria(ruta);
				}else if(ruta.modo === 2){
					this.buscar_productos_subcategorias(ruta)
				}
				this.$store.state.Listado.historial.splice(posicion,1);
			}else{
				history.back();
			}

		}	
  }
}