<template>
	<v-container>
    <v-row  justify="space-around">

      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" :timeout="2000"  > 
        <strong class="subtitle-1 font-weight-black"> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      
      <!-- Productos -->
      <v-col cols="12" sm="5" md="4" lg="3"  v-for="(item, i) in searchProducto" :key="i" v-if="i >= countMin && i <= countMax ">
        <v-card class="shadowCard lateral" height="100%">
          <!-- Descuento mostrado -->
          <v-btn 
            color="primary" 
            absolute
            top
            left
            class="v-btn--descuento text-caption"
            x-small
            rounded
            v-if="item.pjedesc > 0"
          >
            {{ item.pjedesc }}% Descuento
          </v-btn>

          <!-- Ver el artículo -->
          <v-btn 
            icon
            absolute
            top
            right
            class="v-btn--descuento text-caption"
            small
            rounded
            @click="ver(item)"
          >
            <v-icon color="black">mdi-eye</v-icon>
          </v-btn>

          <v-tooltip bottom v-if="getusuarioFetish">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                icon
                absolute
                top
                right
                class="v-btn--descuento text-caption mt-7"
                small
                rounded 
                v-bind="attrs" 
                v-on="on" 
                @click="aniadir_lista_deseos(item)"
              >
                <v-icon color="red">mdi-heart</v-icon> 
              </v-btn>
            </template>
            <span>Añadir a lista de deseos</span>
          </v-tooltip>


          <!-- IMAGEN PRINCIPAL -->
          <v-img 
            :src="url + item.foto" 
            height="250px" 
            contain 
            v-if="item.foto"
            @click="ver(item)"
            class="white"
          >
          </v-img>
          
          <!-- SI NO HAY IMAGEN INCERTO DEFAULT-->
          <v-img 
            :src="`${url + photo}`" 
            height="250px"
            contain 
            v-else 
            @click="ver(item)" 
            color="secondary" 
          />

          <v-card-text class="pb-4">
            <v-row>
              <v-col cols="12" class="pb-0">
                <span class="text-subtitle-1 font-weight-bold">{{item.nomart}}</span>
              </v-col>
              <v-col cols="12" class="pt-0 pb-6">
                <!-- Precio normal -->
                <span v-if="item.pjedesc === 0"><h4 class="primary--text mr-2 mb-0"> {{ item.precio1 | currency(2) }} MX </h4></span>
                <!-- Precio con descuento -->
                <span v-else class="primary--text mr-2 mb-0"> 
                  <h4 class="primary--text mr-2 mb-0">
                    {{ (item.precio1) - (item.pjedesc / 100) * (item.precio1 ) | currency(2) }} MX
                    <span class="text-decoration-line-through grey--text" v-if="item.pjedesc > 0"> 
                      ${{ item.precio1 }} 
                    </span>
                  </h4> 
                </span>
              </v-col>
            </v-row>

            <v-btn 
              color="primary" 
              outlined
              absolute
              button
              right
              @click="agregar_a_carrito(item)"
              class="v-btn--example"
            >
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-card-text>
        
          <v-card-actions class="subtitle-1 font-weight-bold secondary--text py-0" >
            
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" align="center">
        <v-pagination
          v-model="page"
          :length="paginacion"
          :total-visible="7"
        ></v-pagination>
      </v-col>
    </v-row>
	</v-container >

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
	import  metodos from '@/mixins/metodos.js';
  import card_articulo from '@/components/card_articulo.vue'

  export default {
		mixins:[metodos],
    components: {
      card_articulo
    },

    data(){
      return{
        model: null,
        productos:[],
        alerta: { activo: false, texto:'', color:'error' },
        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png',

        page: 1,
        paginas:0,
				countMax: 7,
        countMin: 0,
      }
    },

    watch:{
      EstadoCarrito () {
        this.$store.dispatch('abrirCarrito',this.EstadoCarrito)
      },

      page(){
        var itemPerPage = this.page * 8
        this.countMax = itemPerPage - 1
        this.countMin = (this.countMax + 1) - 8
      }
    },

    computed:{
      ...mapGetters('login',['getusuarioFetish']),
      ...mapGetters('Listado',['getSearch']),

      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -50
      },

      searchProducto( ){
        if(this.productos){
          return this.productos.filter((art)=>{
            var nom = art.nomart.toLowerCase();
            return nom.match(this.getSearch.toLowerCase());
          })
        }
      },

      paginacion(){
        if((this.searchProducto.length /8)%2==0){
          return this.paginas = this.searchProducto.length /8
        }else{
          return this.paginas = parseInt((this.searchProducto.length /8) + 1)
        }
      },
    },

    created(){
      this.init();
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),

      init(){
        this.$http.get('obtener.articulos').then( response =>{
          this.productos = response.body
        })
      },

      agregar_a_carrito(item){
        const payload = new Object({
          id           : item.id,
          codigo       : item.codigo,
          nomart       : item.nomart, 
          precio1      : item.precio1,
          precio2      : item.precio2,
          pjedesc      : item.pjedesc,
          descrip      : item.descrip,
          descripLarga : item.descripLarg,
          largo        : item.largo,
          ancho        : item.ancho,
          alto         : item.alto,
          peso         : item.peso,
          foto         : item.foto,
          envoltorio   : item.envoltorio,
          cantidad     : 1
        })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito();
        })
      },

      aniadir_lista_deseos(item){
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,

        })
        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },

      ver (item){
        this.$router.push({ name: 'detalle.del.producto',params:{id:item.id}})
      },

    } 
    
  }
</script>

<style>
  .lateral .v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    padding: 0px !important;
    min-width: 50px !important;
  }

  .lateral .v-btn--descuento{
    bottom: 0;
    position: absolute;
    margin: 0 0 0px 0px;
    min-width: 50px !important;
    z-index: 1;
  }

</style>