<template>
  <v-app id="inspire" >

    <v-navigation-drawer v-model="drawer" temporary app width="300px" class="mt-1" style="border-radius:14px">

      <v-list dense nav class="py-0">
        <v-list-item two-line >
          <v-list-item-avatar tile size="30px" > 
            <img src="cursor.png" >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-primary subtitle-2 text-left" dark> 
              <span class="subtitle-2 font-weight-bold">{{ getusuarioFetish.nombre }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="gray"></v-divider>
    
      <v-list shaped >
        <template v-for="control in AppControl">
          <v-list-item
            v-for="(child, i) in control.admin"
            :key="i"
            :to="child.path"
            color="accent"
            outlined
          >
            <v-list-item-content >
              <v-list-item-title >
                <span class="font-weight-medium subtitle-2">{{ child.text }}  </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-icon small color="primary">{{ child.icon }}</v-icon> 
          </v-list-item>
        </template>
      </v-list>
  
      <v-footer absolute app>
        <v-spacer></v-spacer>
        <v-btn 
          icon 
          class="mr-10" 
          href="https://www.facebook.com/sofsolutiondesarrollo" 
          target="_blank"
        > 
          <v-icon  large>mdi-facebook</v-icon>
        </v-btn>
        
        <v-btn 
          icon 
          class="mr-10" 
          href="https://www.instagram.com/sofsolution_software/" 
          target="_blank"
        >
          <v-icon large>mdi-instagram</v-icon>
        </v-btn>
        
        <v-spacer></v-spacer>
      </v-footer>
    </v-navigation-drawer>

    <v-app-bar 
      app 
      tabs 
      class="white elevation-0"
    >

      <v-btn icon @click.stop="drawer = !drawer" v-if="getusuarioFetish"> <v-icon>mdi-menu</v-icon> </v-btn>

      <img 
        src="@/assets/logo_innovalab.jpg" 
        type="button" 
        height="65px" 
        @click="Inicio()" 
        class="my-5"
        v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'"
      >

      <v-tabs
        color="#042737"
        right
      >
        <v-tab @click="vista(1)">INICIO</v-tab>
        <v-tab @click="vista(2)">PRODUCTOS</v-tab>
        <v-tab @click="vista(3)">SERVICIOS</v-tab>
        <v-tab @click="vista(4)">MARCAS</v-tab>
        <v-tab @click="vista(5)">EMPRESA</v-tab>
        <v-tab @click="vista(6)">BLOG</v-tab>
        <v-tab @click="vista(7)">CONTACTO</v-tab>
      </v-tabs>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer right temporary app v-model="$store.state.Carrito.carritovisible"  width="400px">
      <div class="pa-2"><CARRITO/></div> 
      <div class="mt-12"></div>
      <v-footer absolute color="white" class="pa-2" >
        <v-spacer></v-spacer>
        <v-btn dark block  @click="ver_carrito()" outlined color="orange"> Seguir comprando  </v-btn>
      </v-footer>	
    </v-navigation-drawer>   

    <v-main>
      <v-slide-y-transition mode="out-in" >
        <router-view  />
      </v-slide-y-transition>
    </v-main>
  </v-app>
</template>

<script>
  import store from '@/store'
  import {mapActions , mapGetters, mapMutations} from 'vuex'
  import CARRITO  from '@/components/Carrito.vue'
  import login    from '@/views/Login/login.vue'
  import register from '@/views/Login/register.vue'
	import metodos from '@/mixins/metodos.js';

  export default {
		mixins:[metodos],
    name: 'App',
    components: {
      CARRITO,
      login,
      register,
    },

    data: () => ({
      active:false,
      tabs: 1,
      drawer: false,
      familias:[],
      Iniciar_Sesion: false,
      login : true,
      sesion: {},
			alert : { active: false, text:'', color:'error' },
      search:'',

       AppControl: [
        {
          admin: [ 
            { id:2, text: 'Detalle de la cuenta' , icon: 'mdi-arrow-right'     ,path: '/detalle.cuenta'},
            { id:3, text: 'Pedidos'              , icon: 'mdi-arrow-right'     ,path: '/pedidos'       },
            { id:4, text: 'Direcciones de envío'          , icon: 'mdi-arrow-right'     ,path: '/direcciones'   },
            //{ id:5, text: 'Direcciones de facturación'    , icon: 'mdi-arrow-right'     ,path: '/direcciones.facturacion'   },
            { id:6, text: 'Lista de deseos'      , icon: 'mdi-arrow-right'     ,path: '/deseos'    },
            ],
        },
      ],

      EstadoCarrito:false
    }),

    async created(){
      await this.configuracion_de_colores();
    },

    watch:{
      getusuarioFetish(){
         this.$store.state.Listado.historial = []
      },

      search(){
        if(this.$router.history.current.name != 'catarts'){
          this.$router.push({name:'catarts'})
        }
        this.buscar(this.search)
      }
    },

    computed:{
      ...mapGetters('Carrito',['getCarrito']),
      ...mapGetters('login',['getusuarioFetish']),
      ...mapGetters(['links']),
    },

    methods:{
      ...mapMutations(['toggleDrawer']),
      
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },

      ...mapActions('login',['salirLogin','guardar_ciaws']),
      ...mapActions('Carrito',['limpiarCarrito']),
      ...mapActions('Listado',['buscar_productos_categoria','buscar_productos_subcategorias','guardar_historial_productos','buscar']),

      configuracion_de_colores(){
        this.$http.get('config.list').then(response=>{
          // console.log('CONFIG', response.body)
          let ciaws = response.body
          this.guardar_ciaws(ciaws)
          return true;
        });
      },

      Inicio(){
        this.$router.push({ name:'Home', params:{ inicio: true } }) 
      },


      vista( value ){
        switch( value ){

          case 1:
            this.$router.push({name: 'Home' })
          break; 

          case 2:
            this.$router.push({name: 'catarts' })
          break; 

          case 3:
            this.$router.push({name: 'Servicios' })
          break; 

          case 4:
            this.$router.push({name: 'Marcas' })
          break; 

          case 5:
            this.$router.push({name: 'Empresa' })
          break; 
        }
      }
    }

  };
</script>

<style >

  main{
    background: #f6f9fc !important;
  }

  .v-main{
    background: #f6f9fc !important;
  }

  html{
    background: #f6f9fc;
  }

  .v-main__wrap{
    background: #f6f9fc !important;
  }

  .shadowCard{
    border-radius: 8px !important; 
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.1)  !important;
  }

    /*
  .navigation{
    background: #000000;  
    background: -webkit-linear-gradient(to right, #25252597, rgb(37, 37, 37));  
     background: linear-gradient(to right, #24710b95, #0c7b0c78),url('http://producciongama.com/CRM-GAMA-2020/imagenes/fondo2.jpg'); 
    background: linear-gradient(to right, #25252584, rgba(37, 37, 37, 0.578));
    background-size: cover;
    padding: 0px;
    margin: 0px;
    width: auto;
    height: 100%;
  }
   .titulo{
    color: #fff;
    font-family: 'Parisienne', cursive;
    font-size: 25px;
    text-shadow:
      0 0 5px rgb(208, 99, 27),
      0 0 10px rgb(255, 183, 0),
      0 0 20px rgb(255, 111, 0),
      0 0 40px rgb(127, 74, 38),
      0 0 80px rgb(127, 81, 38),
      0 0 90px rgb(127, 88, 38),
      0 0 100px rgb(127, 106, 38),
      0 0 140px rgb(127, 102, 38),
      0 0 180px rgb(127, 94, 38); 
  }

   /* #contenedor{
      background: #de5d00;  
      background: -webkit-linear-gradient(to right, #000000e5, #050505db, #000000e5);  
      background: linear-gradient(to right, #000000e5, #050505db, #000000e5); 
      border-radius:25px;
    } */

  /* .url{cursor:url(http://falconmasters.com/img/cursor.gif), auto;} */
  /* *{cursor:url(/cursor.png), auto  } */


 

  
 
</style>
  