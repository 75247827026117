import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/es5/util/colors'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import es from 'vuetify/es5/locale/es';


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
			themes: {
				light: {
					primary  : '#9461c9', 
					secondary: colors.orange.darken2, 
					accent   : colors.pink.darken2,  
					error    : colors.red.darken1,
					info     : colors.blue.darken1,
					success  : colors.green.darken2,
					warning  : colors.teal,
					darked   : colors.grey.darken4,
					textocolor: '#565656'
				},
			},
		},

    lang:{
        locales:{ es },
        current: 'es'
    }
});
